<template>
  <v-container>
    <eagle-form :form-key="formKey"></eagle-form>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './providerRegister'
import providerApplyStatus from "@/modules/provider/config/providerApplyStatus.ts"
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'provider-register-form',
    meta: {},
    isRegistered: false,
  }),
  computed: {
    siteName() {
      return this.$store.getters['base/siteName']
    },
    providerId() {
      return this.$store.getters['member/providerId']
    },
    forceCreate() {
      return this.$route.query.forceCreate
    },
  },
  methods: {
    // async validRegister() {
    //   this.$store.dispatch('loading/active')
    //   try {
    //     const res = await this.$api.collection.providerApi.validRegister()
    //     this.meta.isRegistered = true
    //     this.$snotify.error(
    //       null,
    //       this.$t('provider.error.register')
    //     )
    //   } catch(err) {
    //     // 404代表還沒註冊
    //   } finally {
    //     this.$store.dispatch('loading/close')
    //   }
    // },
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    afterSaveSuccessCallback(result) {
      this.$helper.closeLiff(1)
    },
    async readApi() {
      const defaultData = {
        name: null,
        member_name: null,
        member_phone: null,
        email: null,
        city: null,
        district:null,
        address:null
      }
      try {
        if(!this.providerId || this.forceCreate) {
          return defaultData
        }
        const res = await this.$api.collection.providerApi.read(this.providerId)
        this.$snotify.error(
          null,
          this.$t('provider.error.register')
        )
        this.isRegistered = true
        this.$store.dispatch('base/setLayoutProvider', res)
        return res
      } catch (err) {
        console.error(err)
        return defaultData
      }
    },
    async updateApi(target, formData) {
      this.$root.gaLogEvent('供應商_點擊_註冊')
      const res =  await this.$api.collection.providerApi.create(formData)
      this.$store.dispatch('base/setLayoutProvider', res)
      if (this.forceCreate) {
        this.$router.replace({name: 'provider-switch-identity'})
      }
      return res
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
