<template>
  <section>
    <!-- <div class="d-block storeTop">
      <v-img
        src="~@/assets/linerpbg-color.png"
        width="100%"
        height="150"
        center
        class="storeTopImg"
      >
      </v-img>
      <div class="storeTopText pa-3">
        <h1>{{ providerName }}</h1>
      </div>
      <div class="storeTopBg"></div>
    </div> -->
    <v-container class="pb-0" @click="infoShow = !infoShow">
      <v-btn color="primary" icon x-small style="display: block; margin-left: auto;">
        <v-icon v-if="infoShow">fa-solid fa-chevron-up</v-icon>
        <v-icon v-else>fa-solid fa-chevron-down</v-icon>
      </v-btn>
      <div class="my-0" v-if="infoShow">
        <p v-html="nl2br(info)" class="pb-2 mb-0" style="word-break: break-word;"></p>
      </div>
    </v-container>
  </section>
</template>

<script>
import headerInfo from "@/components/liffList/headerInfo/headerInfo.js";

export default {
  components: {},
  mixins: [headerInfo],
  data: () => {
    return {
      infoShow: true
    }
  },
  computed: {
    layoutProvider() {
      return this.$store.getters["base/layoutProvider"];
    },
    providerName() {
      return this.layoutProvider.name;
    },
    info() {
      if (!this.layoutProvider) {
        return ''
      }
      return this.layoutProvider.welcome_text;
    },
  },
};
</script>

<style lang="sass">
.storeTop
  position: relative
  .storeTopText
    position: absolute
    left: 0
    bottom: 0
    color: #ffffff
    font-weight: 700
    z-index: 10
  .storeTopBg
    width: 100%
    height: 100%
    background: linear-gradient(180deg, rgba(11, 26, 43, 0.274947479) 0%, #0c0c0ca1 100%)
    mix-blend-mode: initial
    position: absolute
    top: 0
    left: 0
    z-index: 8
</style>