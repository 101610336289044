import Vue from 'vue'
import mixins from 'vue-typed-mixins'
import initModules from 'app/initModules'
import initEventListeners from 'app/initEventListeners'
import 'app/initPlugins'
import { mapGetters } from 'vuex'
import api from 'kernel/api/api'
import eagleEnv from 'config/env.json'
import recaptcha from 'kernel/libs/recaptcha'
Vue.prototype.$api = api(eagleEnv)
import { liffClientId } from 'config/env.json'
import helper from 'kernel/helper/helper'

export default mixins(
  initModules,
  initEventListeners,
).extend({
  methods: {
    async initClient() {
      // await this.$helper.delay(0.2)
      // const queryLiffId = this.$route.query.liffId
      // // @ts-ignore
      // if (queryLiffId && window.liff.isInClient() === true) {
      //   // @ts-ignore
      //   await window.liff.init({
      //     liffId: queryLiffId,
      //   })
      // }
      this.$store.dispatch('loading/active')
      this.$store.dispatch('base/setupLayoutReady', false)
      // @ts-ignore
      await this.initKernel()
      await this.setupEventListeners()
      await this.initModules()
      // @ts-ignore
      await this.loadApplication()
      this.$store.dispatch('base/setupLayoutReady', true)
      this.$store.dispatch('loading/close')
    },
    async initKernel() {
      window.helper.setupVueModel(this)
      this.$api.setup(this)
      this.$tokenStore.setupApi(this.$api)
      await this.$helper.delay(0.3)
    },
    async loadApplication() {
      try {
        const providerId = this.isLiffEntry ? this.liffStateProviderId : this.routeProviderId
        // const params = Vue.prototype.$helper.whereLayout() == "provider-store" ? { goToProvider: providerId } : null
        const result = await this.$api.collection.baseApi.application()
        this.$store.dispatch('base/initApplication', result)
        if (result.providerInfo) this.$store.dispatch(`base/setLayoutProvider`, result.providerInfo)
      } catch (error) {
        console.error(error)
        if (error === undefined) {
          this.$systemBar.active({
            title: this.$t('error.server_fail'),
            content: this.$t('error.server_fail.content'),
            color: 'error',
          })
        }

        this.$store.dispatch('loading/close')
      } finally {
        // @ts-ignore
        this.initRecaptcha()
      }
    },
    initRecaptcha() {
      Vue.prototype.$recaptchaLibrary = recaptcha(this)
    },
  },
  computed: {
    ...mapGetters({
      layoutReady: 'base/layoutReady',
    }),
    routeProviderId() {
      return this.$route.params.providerId
    },
    // 是否從 liff.line.me 進入的
    isLiffEntry() {
      return !!this.liffState
    },
    liffState() {
      return this.$route.query[`liff.state`]
    },
    liffStateProviderId() {
      if (this.routeProviderId) return null
      if (!this.liffState) return null

      // this.liffState可能是 /123 or /123/store/check 之類的格式
      // @ts-ignore
      return this.liffState.match(/^\/([0-9]+)(\/)?/)[1]
    },
  },
  components: {
    loading: () => import('components/loading/loading.vue'),
    progressLoading: () => import('components/loading/progressLoading.vue'),
  },
})
