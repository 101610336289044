import Vue from "vue";
import _cloneDeep from "lodash/cloneDeep";
import { ActionContext } from "vuex/types";
// @ts-ignore
import storage from "libs/storage/localStorage.js";

export default {
  namespaced: true,
  state: {
    profile: null,
  },
  mutations: {
    set(state: any, data: string) {
      state.profile = _cloneDeep(data);
    },
    clean(state: any) {
      state.profile = null;
    },
  },
  actions: {
    set(context: ActionContext<any, any>, data: string) {
      context.commit("set", data);
      const storageInstance = storage();

      // 只有在 `chooseStoreId` 不存在時才設置
      if (!storageInstance.get("chooseStoreId")) {
        storageInstance.set("chooseStoreId", context.getters.storeId);
      }

      // 只有在 `chooseProviderId` 不存在時才設置
      if (!storageInstance.get("chooseProviderId")) {
        storageInstance.set("chooseProviderId", context.getters.providerId);
      }
    },
    clean(context: ActionContext<any, any>) {
      context.commit("clean");
      const storageInstance = storage();
      storageInstance.delete("chooseStoreId");
      storageInstance.delete("chooseProviderId");
    },
    switchSelectedProvider(
      context: ActionContext<any, any>,
      selectedId: string
    ) {
      const providerList = context.getters.memberProviders.map((item: any) => {
        item.isSelected = item.provider_id === selectedId;
        return item;
      });
      const editProfile = JSON.parse(JSON.stringify(context.getters.profile));
      editProfile.hasProvider = _cloneDeep(providerList);

      const storageInstance = storage();
      storageInstance.set("chooseProviderId", selectedId);
      context.dispatch("set", editProfile);

      Vue.prototype.$helper
        .vueRouter()
        .replace({ path: `/provider/${selectedId}/identity/switch` });
      Vue.prototype.$snotify.success("切換成功");
    },
    switchSelectedStore(context: ActionContext<any, any>, selectedId: string) {
      const storeList = context.getters.memberStores.map((item: any) => {
        item.isSelected = item.store_id === selectedId;
        return item;
      });
      const editProfile = JSON.parse(JSON.stringify(context.getters.profile));
      editProfile.hasStore = _cloneDeep(storeList);

      const storageInstance = storage();
      storageInstance.set("chooseStoreId", selectedId);
      context.dispatch("set", editProfile);

      Vue.prototype.$snotify.success("切換成功");
    },
  },
  getters: {
    profile: (state: any) => {
      if (!state.profile) return null;
      return state.profile;
    },
    hasProvider: (state: any, getters: any): boolean => {
      if (!getters.profile) return false;
      return getters.profile.hasProvider.length > 0;
    },
    hasStore: (state: any, getters: any): boolean => {
      if (!getters.profile) return false;
      return getters.profile.hasStore.length > 0;
    },
    memberProviders: (state: any, getters: any): [] => {
      if (!getters.hasProvider) return [];
      return getters.profile.hasProvider;
    },
    firstProviderId: (state: any, getters: any) => {
      if (!getters.hasProvider) return null;
      return getters.memberProviders[0].provider_id;
    },
    firstProviderName: (state: any, getters: any) => {
      if (!getters.hasProvider) return null;
      return getters.memberProviders[0].provider_name;
    },
    memberStores: (state: any, getters: any): [] => {
      if (!getters.hasStore) return [];
      return getters.profile.hasStore;
    },
    selectedStore(state: any, getters: any) {
      const fromStorage = storage().get("chooseStoreId");
      if (getters.memberStores.length == 0) return null;
      if (
        getters.memberStores.filter(
          (item: any) => item.isSelected || +item.store_id === +fromStorage
        ).length === 0
      )
        return getters.memberStores[0];
      return getters.memberStores.filter(
        (item: any) => item.isSelected || +item.store_id === +fromStorage
      )[0];
    },
    selectedProvider(state: any, getters: any) {
      const fromStorage = storage().get("chooseProviderId");
      if (getters.memberProviders.length == 0) return null;
      if (
        getters.memberProviders.filter(
          (item: any) => item.isSelected || +item.provider_id === +fromStorage
        ).length === 0
      )
        return getters.memberProviders[0];
      return getters.memberProviders.filter(
        (item: any) => item.isSelected || +item.provider_id === +fromStorage
      )[0];
    },
    storeId: (state: any, getters: any) => {
      // const fromStorage = storage().get('chooseStoreId')
      // if(fromStorage) return fromStorage

      if (!getters.selectedStore) return "";
      return getters.selectedStore.store_id;
    },
    providerId: (state: any, getters: any) => {
      // const fromStorage = storage().get('chooseProviderId')
      // if(fromStorage) return fromStorage

      if (!getters.selectedProvider) return "";
      return getters.selectedProvider.provider_id;
    },
    storeName: (state: any, getters: any) => {
      if (!getters.selectedStore) return "";
      return getters.selectedStore.store_name;
    },
    providerName: (state: any, getters: any) => {
      if (!getters.selectedProvider) return "";
      return getters.selectedProvider.provider_name;
    },
    isStoreMainManager: (state: any, getters: any) => {
      if (!getters.selectedStore) return false;
      return getters.selectedStore.isStoreMaster;
    },
    isProviderMainManager: (state: any, getters: any) => {
      if (!getters.selectedProvider) return false;
      return getters.selectedProvider.isProviderMaster;
    },
    currMemberRole: (
      state: any,
      getters: any,
      rootState: any,
      rootGetters: any
    ) => {
      const tokenRole = rootGetters["token/tokenRole"];
      if (tokenRole) {
        const key =
          tokenRole.charAt(0).toUpperCase() + tokenRole.slice(1).toLowerCase();
        const res = getters["selected" + key];
        // 'signer' 'picker' 'dispatcher' 'freightman'

        if (res) {
          // 依需求B2C會發生會員登入時候是沒有對應任何供應商的情況增加判斷
          const isCurrMasterKeyName = "is" + key + "Master"
          const hasCurrMasterKey = Object.keys(res).includes(isCurrMasterKeyName)

          // 判斷是否為主要管理者
          // res._isMaster = res["is" + key + "Master"];
          res._isMainManager = hasCurrMasterKey ? res[isCurrMasterKeyName] : null;
          
          // 判斷是否為共同管理員
          // res._isCoAdmin = !res["is" + key + "Master"] && res.roles.length == 0;
          res._isCoManager = hasCurrMasterKey ? !res[isCurrMasterKeyName] && res.roles.length == 0 : null;

          // 判斷是否為 管理者
          // res._isAdmin = res._isPrimaryManager || res._isCoManager
          res._isManager = res._isMainManager || res._isCoManager

          // res.roles = ['dispatcher']
          // res._isMainManager = false;
          // res._isManager = false;
        }
        return res;
      } else {
        return tokenRole;
      }
    },
  },
};
