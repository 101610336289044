import { render, staticRenderFns } from "./freightRules.vue?vue&type=template&id=37151072&scoped=true&"
import script from "./freightRules.vue?vue&type=script&lang=js&"
export * from "./freightRules.vue?vue&type=script&lang=js&"
import style0 from "./freightRules.vue?vue&type=style&index=0&id=37151072&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37151072",
  null
  
)

export default component.exports