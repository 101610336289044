<template>
  <div
    class="mt-20"
    v-if="
      apiData.length > 0 &&
        (mergeFreeData.freezing.main_island.amount !== 0 ||
          mergeFreeData.freezing.outlying_islands.amount !== 0 ||
          mergeFreeData.normal.main_island.amount !== 0 ||
          mergeFreeData.normal.outlying_islands.amount !== 0 ||
          mergeFreeData.refrigeration.main_island.amount !== 0 ||
          mergeFreeData.refrigeration.outlying_islands.amount !== 0)
    "
  >
    <h3 class="mb-4">運費規則說明：</h3>
    <ol class="fr-ol">
      <li v-for="(item, index) in freightText" :key="index">{{ item }}</li>
      <li>以上資訊供參考，實際情況以供應商計價為準。</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "freightRules",
  data() {
    return {
      freightText: [],
      apiData: [],
      mergeFreeData: {
        normal: {
          main_island: {
            free: 0,
            amount: 0,
          },
          outlying_islands: {
            free: 0,
            amount: 0,
          },
        },
        refrigeration: {
          main_island: {
            free: 0,
            amount: 0,
          },
          outlying_islands: {
            free: 0,
            amount: 0,
          },
        },
        freezing: {
          main_island: {
            free: 0,
            amount: 0,
          },
          outlying_islands: {
            free: 0,
            amount: 0,
          },
        },
      },
      area: [
        "main_island",
        "outlying_islands",
        "main_island_free",
        "outlying_islands_free",
      ],
      temperature: ["normal", "refrigeration", "freezing"],
    };
  },
  async mounted() {
    this.apiData = await this.readApi();

    const text = {
      main_island: "本島",
      outlying_islands: "離島",
      main_island_free: "本島免運",
      outlying_islands_free: "離島免運",
      normal: "常溫",
      refrigeration: "冷藏",
      freezing: "冷凍",
    };

    const mergeFree = {
      normal: {},
      refrigeration: {},
      freezing: {},
    };

    for (let item of this.apiData) {
      let area = item.area.replace("_free", "");
      let temperature = item.temperature;

      if (!mergeFree[temperature]) {
        mergeFree[temperature] = {};
      }

      if (!mergeFree[temperature][area]) {
        mergeFree[temperature][area] = {
          free: 0,
          amount: 0,
        };
      }

      if (item.area.includes("free")) {
        mergeFree[temperature][area].free = item.amount;
      } else {
        mergeFree[temperature][area].amount = item.amount;
      }
    }
    this.mergeFreeData = mergeFree;
    let isOutLyingIsland = false;
    for (let row of ["main_island", "outlying_islands"]) {
      for (let col of this.temperature) {
        if (mergeFree[col] && mergeFree[col][row]) {
          let amount = mergeFree[col][row].amount;
          if (amount === 0) {
            continue;
          }
          let free = mergeFree[col][row].free;
          let intro = `${text[col]}配送${amount}元`;
          // 如果離島有資料
          if (row === "outlying_islands") {
            intro = `${text[row]}${text[col]}配送${amount}元`;
            isOutLyingIsland = true;
          }
          //沒填免運門檻 免運費不顯示
          if (free > 0) {
            intro += `，滿${free}免收運費。`;
          }
          this.freightText.push(intro);
        }
      }
    }
    // 僅出貨臺灣本島，如果沒有填離島資訊就顯示這一行
    if (!isOutLyingIsland) {
      this.freightText.push("僅出貨臺灣本島");
    }
  },
  methods: {
    async readApi() {
      try {
        const res = await this.$api.collection.providerApi.getFreightPrice(
          this.providerId
        );
        return res;
      } catch (err) {
        console.error(err);
        return [];
      }
    },
  },
  computed: {
    providerId() {
      return this.$route.params.providerId || this.layoutProviderId
    },
    layoutProviderId() {
      return this.$store.getters["base/layoutProviderId"]
    },
  },
};
</script>

<style scoped>
.fr-ol {
  font-size: 15px;
}
/* Your styles go here */
</style>
